import React from 'react';
import cn from 'classnames';

import styles from '~/about-us/craftspeople/craftsperson/Craftsperson.module.scss';

export const CraftspersonList = ({
	list,
	activeID,
	showHeader = true,
	isAccordion = false,
}) => {
	const ulClasses = cn(
		styles.CraftpersonsList,
		{ [styles['CraftpersonsListAccordion']]: isAccordion },
	);

	return (
		<>
			{showHeader &&
				<h2 id="navLabel" className="tw-text-sm tw-font-semibold tw-mb-2">Our Craftspeople</h2>
			}
			<nav aria-labelledby="navLabel">
				<ul className={ulClasses}>
					{list.map((item, index) => {
						const {
							id,
							title,
							url,
						} = item;
						const listItemClasses = cn(
							{ [styles['is-active']]: id === activeID }
						);
						return (
							<li key={index}>
								<a data-tr-link-event-comp-name="our craftspeople"
									data-tr-link-event-comp-type="subnavigation"
									id={id}
									href={`/${url}`}
									className={listItemClasses}>
									{title}
								</a>
							</li>
						);
					})}
				</ul>
			</nav>
		</>
	);
};
