import React from 'react';
import { observer } from 'mobx-react';

export const MagicTabccordionTabDescription = observer(({ groupDescription }) => {
	const {
		descriptionClass = 'Heading Heading--5 tw-text-gray tw-mb-5',
		description,
		descriptionTag,
	} = groupDescription;

	let desc;

	// check to see if the description is a function
	if (typeof description === 'function') {
		desc = <p className={descriptionClass}>{description()}</p>;
		if (descriptionTag) {
			desc = <descriptionTag className={descriptionClass}>{description()}</descriptionTag>;
		}
		return desc;
	}
	// by default wrap the description in an p, unless there is a descriptionTag attribute
	desc = <p className={descriptionClass}>{description}</p>;
	if (descriptionTag) {
		desc = <descriptionTag className={descriptionClass}>{description}</descriptionTag>;
	}

	return (
		<>
			{ desc }
		</>
	);
});
