import React, { createContext, useContext } from 'react';
import { enableStaticRendering } from 'mobx-react';

import { aboutUsSubNavData } from '~/components/content-page-subnav/about-us-subnav.data';
import { isOnServer } from '~/global/global.constants';
import { MagicTabccordionStore } from '~/components/magic-tabccordion/Stores/magicTabccordion.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';

const craftspeopleContext = createContext();

enableStaticRendering(isOnServer);

export const CraftspeopleContextProvider = (props) => {
	const {
		children,
		pageProps: {
			pageData: {
				contentBlocks = [],
				craftpersonsAccordion,
				id,
				intro,
				metaDescription = '',
				seeAllLink,
				shareImage,
				title = '',
			} = {},
		} = {},
	} = props;

	const {
		linkEventStore,
	} = useGlobalContext();

	// eslint-disable-next-line no-shadow
	const allCraftspeople = aboutUsSubNavData[0].children.find(({ title }) => title === 'Meet Our Craftspeople').children;

	const accordionStore = new MagicTabccordionStore(craftpersonsAccordion, linkEventStore);

	const context = {
		accordionStore,
		allCraftspeople,
		contentBlocks,
		id,
		intro,
		metaDescription,
		seeAllLink,
		title,
		shareImage,
	};

	return <craftspeopleContext.Provider value={context}>{children}</craftspeopleContext.Provider>;
};

export const useCraftspeopleContext = () => useContext(craftspeopleContext);
