import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { MagicTabccordionGroup } from '~/components/magic-tabccordion/Components/MagicTabccordionGroup';

export const MagicTabccordionContainer = observer(({
	store, className = '', style = {}, noBorder = false,
}) => {
	if (!store || !store.tabGroups) {
		return null;
	}
	const wrapperClasses = cn(className, {
		'Tabccordion--tabs': store.isTabs,
		'Tabccordion--accordion': store.isAccordion,
		'Tabccordion--responsive': store.isResponsive,
		'no-border': noBorder,
	});

	return (
		<div className={`Tabccordion-wrapper ${wrapperClasses}`} style={style}>
			{(store.tabGroups).map((group) => {
				const id = group.groupID || null;

				return (
					group.isHidden !== true &&
					<MagicTabccordionGroup
						key={group.groupName}
						className={group.className}
						tabs={group.tabs}
						groupHeader={group.groupHeader}
						store={store}
						id={id}
					/>
				);
			})}
		</div>
	);
});
