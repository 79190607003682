import React from 'react';

import { CraftspersonList } from '~/about-us/craftspeople/craftsperson/Components/CraftspersonList';
import { useCraftspeopleContext } from '~/about-us/craftspeople/craftsperson/Contexts/Craftspeople.context';

export const CraftspeopleRenderer = () => {
	const {
		allCraftspeople,
		id,
	} = useCraftspeopleContext();

	return (
		<CraftspersonList list={allCraftspeople} activeID={id} showHeader={false} isAccordion={true} />
	);
};
