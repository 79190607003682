import React from 'react';
import { observer } from 'mobx-react';

import { MagicTabccordionTabDescription } from '~/components/magic-tabccordion/Components/MagicTabccordionTabGroupDescription';

const defaultGroupHeader = (groupHeader) => {
	const {
		description,
		header,
		headerClass = 'Heading Heading--3 tw-text-gray tw-mb-3',
	} = groupHeader;

	// by default wrap the tab title in an h2, unless there is a tabTitleTag attribute
	let headerTitle = <h2 className={headerClass}>{header}</h2>;

	if (groupHeader.headerTag) {
		headerTitle = <groupHeader.headerTag className={headerClass}>{header}</groupHeader.headerTag>;
	}

	return (
		<div>
			{headerTitle}
			{description && <MagicTabccordionTabDescription groupDescription={groupHeader} />}
		</div>
	);
};

export const MagicTabccordionTabGroupHeader = observer(({ groupHeader }) => {
	if (groupHeader.renderer) {
		const GroupHeaderRenderer = groupHeader.renderer;
		return <GroupHeaderRenderer data={groupHeader} />;
	}
	return defaultGroupHeader(groupHeader);
});
