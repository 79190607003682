/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { MagicTabccordionTab } from '~/components/magic-tabccordion/Components/MagicTabccordionTab';
import { MagicTabccordionAccordionContent } from '~/components/magic-tabccordion/Components/MagicTabccordionAccordionContent';
import { MagicTabccordionTabGroupHeader } from '~/components/magic-tabccordion/Components/MagicTabccordionTabGroupHeader';

export const MagicTabccordionGroup = observer((props) => {
	const {
		className = '',
		groupHeader,
		id,
		store,
		store: {
			disableAriaRoles,
			tabGroupContainerClass,
			tabListClass,
		},
		tabs,
	} = props;

	return (
		<div id={id} className={className}>
			{groupHeader && <MagicTabccordionTabGroupHeader groupHeader={groupHeader} />}
			{(!store.isResponsive && !store.isTabs) ? null :
				<ul role={disableAriaRoles ? null : 'tablist'} className={cn('Tabccordion-list', { [tabListClass]: Boolean(tabListClass) })}>
					{tabs.map(tab => <MagicTabccordionTab key={`${id}-tab-${tab.index}`} tab={tab} id={id} store={store} />)}
				</ul>
			}
			<ConditionalWrapper
				condition={tabGroupContainerClass}
				wrapper={children => <div className={tabGroupContainerClass}>{children}</div>}>
				{ tabs.map((tab, i, arr) => {
					const isLast = arr.length - 1 === i;
					return <MagicTabccordionAccordionContent key={`${id}-content${tab.index}`} tab={tab} id={id} store={store} isLast={isLast} />;
				})}
			</ConditionalWrapper>
		</div>
	);
});
