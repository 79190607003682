import React from 'react';

const FAQRenderer = ({ tab }) => {
	return (
		<div>
			{tab.description()}
		</div>
	);
};

export {
	FAQRenderer,
};
