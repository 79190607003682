import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

const TabControl = (props) => {
	const {
		children,
		tab = {},
		tabAttributes = {},
	} = props;

	return tab.href
		? <a href={tab.href} {...tabAttributes}>{children}</a>
		: <button {...tabAttributes}>{children}</button>;
};
export const MagicTabccordionTab = observer(class MagicTabccordionTab extends React.Component {
	constructor(props) {
		super(props);
		this.tabccordionTab = null;
		this.setTabccordionTab = (element) => {
			this.tabccordionTab = element;
		};
	}

	componentDidMount() {
		this.props.store.addTabEventListeners(this.tabccordionTab, this.props.tab);
	}

	componentWillUnmount() {
		this.props.store.removeTabEventListeners(this.tabccordionTab, this.props.tab);
	}

	render() {
		const tabButtonClasses = classNames(this.props.store.tabccordionButtonClass || '', {
			isActive: this.props.tab.isActive,
		});
		const {
			tab,
			store: {
				allowCloseableTabs = false,
				hasActiveTabs = false,
				tabTitleTag: TabTitleTag = null,
			} = {},
		} = this.props;

		// by default wrap the tab title in a span, unless there is a tabTitleTag attribute
		let tabTitle = <>
			<span
				className={classNames(this.props.store.tabTitleClass, { 'tw-hidden md:tw-block': tab.titleMobile })}
			>
				{tab.title}
			</span>
			{tab.titleMobile && <span
				className={classNames(this.props.store.tabTitleClass, 'md:tw-hidden')}
			>
				{tab.titleMobile}
			</span>}
		</>;

		const TabTitleComponent = tab.tabTitleComponent;
		const listElementClass = tab.listElementClass || '';

		if (TabTitleTag) {
			tabTitle = <TabTitleTag className={this.props.store.tabTitleClass}>{tab.title}</TabTitleTag>;
		}

		if (tab.tabTitleElement) {
			tabTitle = tab.tabTitleElement;
		}

		const tabAttributes = {
			'className': `Tabccordion-listItem-button ${tabButtonClasses}`,
			'aria-controls': `${tab.tabGroup}-tabccordion-content${tab.index}`,
			'aria-expanded': allowCloseableTabs ? tab.isActive : null,
			'aria-selected': !allowCloseableTabs ? tab.isActive : null,
			'tabIndex': !hasActiveTabs || this.props.tab.isActive ? 0 : -1,
			'id': `${tab.tabGroup}-tabccordion-tab${tab.index}`,
			'data-qa': `${tab.tabGroup}-tabccordion-content${tab.index}`,
			// the store handles the tracking
			'data-tr-link-event-track': false,
			'role': this.props.store.disableAriaRoles ? null : 'tab',
			'onClick': (e) => {
				e.preventDefault();
				if (!tab.isActive) {
					this.props.store.trackInteration(tab);
				}
				this.props.store.activateTab(tab);
				if (typeof tab.tabOnClick === 'function') {
					tab.tabOnClick();
				}
			},
			'ref': this.setTabccordionTab,
		};

		return (
			<li className={`Tabccordion-listItem ${listElementClass}`} role="presentation">
				<TabControl tab={tab} tabAttributes={tabAttributes}>
					{Boolean(TabTitleComponent) && <TabTitleComponent tab={tab} tabTitleClass={this.props.store.tabTitleClass} />}
					{!TabTitleComponent && tabTitle}
					<span className="tabccordion-status" aria-hidden="true" />
				</TabControl>
			</li>
		);
	}
});
