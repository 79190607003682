export const aboutUsSubNavData = [
	{
		id: 1,
		title: 'About Us',
		url: 'about-us',
		children: [
			{
				id: 30,
				title: 'Meet Our Craftspeople',
				url: 'about-us/craftspeople',
				children: [
					{
						id: 301,
						title: 'American Frame',
						url: 'about-us/craftspeople/american-frame',
					},
					{
						id: 302,
						title: 'American Leather',
						url: 'about-us/craftspeople/american-leather',
					},
					{
						id: 303,
						title: 'American Woods',
						url: 'about-us/craftspeople/american-woods',
					},
					{
						id: 304,
						title: 'Ayomi Yoshida',
						url: 'about-us/craftspeople/ayomi-yoshida',
					},
					{
						id: 305,
						title: 'Bell Manufacturing',
						url: 'about-us/craftspeople/bell-manufacturing',
					},
					{
						id: 342,
						title: 'Bradington-Young',
						url: 'about-us/craftspeople/bradington-young',
					},
					{
						id: 306,
						title: 'Built By Newport',
						url: 'about-us/craftspeople/built-by-newport',
					},
					{
						id: 339,
						title: 'Canal Dover',
						url: 'about-us/craftspeople/canal-dover',
					},
					{
						id: 308,
						title: 'Caperton',
						url: 'about-us/craftspeople/caperton',
					},
					{
						id: 309,
						title: 'Conceria Priante',
						url: 'about-us/craftspeople/conceria-priante',
					},
					{
						id: 310,
						title: 'Delos',
						url: 'about-us/craftspeople/delos',
					},
					{
						id: 311,
						title: 'Eastern Accents',
						url: 'about-us/craftspeople/eastern-accents',
					},
					{
						id: 312,
						title: 'El Greco Woodworking',
						url: 'about-us/craftspeople/el-greco-woodworking',
					},
					{
						id: 340,
						title: 'Fairfield Chair',
						url: 'about-us/craftspeople/fairfield-chair',
					},
					{
						id: 314,
						title: 'Galbraith & Paul',
						url: 'about-us/craftspeople/galbraith-paul',
					},
					{
						id: 343,
						title: 'Haddy',
						url: 'about-us/craftspeople/haddy',
					},
					{
						id: 347,
						title: 'Haruna Niiya',
						url: 'about-us/craftspeople/haruna-niiya',
					},
					{
						id: 315,
						title: 'Hennepin Made',
						url: 'about-us/craftspeople/hennepin-made',
					},
					{
						id: 316,
						title: 'Hickory White',
						url: 'about-us/craftspeople/hickory-white',
					},
					{
						id: 318,
						title: 'Humanscale',
						url: 'about-us/craftspeople/humanscale',
					},
					{
						id: 341,
						title: 'Jen Garrido',
						url: 'about-us/craftspeople/jen-garrido',
					},
					{
						id: 319,
						title: 'John Robshaw',
						url: 'about-us/craftspeople/john-robshaw',
					},
					{
						id: 320,
						title: 'KleinReid',
						url: 'about-us/craftspeople/kleinreid',
					},
					{
						id: 321,
						title: 'Loll Designs',
						url: 'about-us/craftspeople/loll-designs',
					},
					{
						id: 323,
						title: 'Lyndon Furniture',
						url: 'about-us/craftspeople/lyndon-furniture',
					},
					{
						id: 348,
						title: 'Max Home',
						url: 'about-us/craftspeople/max-home',
					},
					{
						id: 324,
						title: 'McCreary Modern',
						url: 'about-us/craftspeople/mccreary-modern',
					},
					{
						id: 325,
						title: 'MH Parks',
						url: 'about-us/craftspeople/mh-parks',
					},
					{
						id: 338,
						title: 'Nicoletti & Calia',
						url: 'about-us/craftspeople/nicoletti-calia',
					},
					{
						id: 326,
						title: 'Obeetee Rugs',
						url: 'about-us/craftspeople/obeetee-rugs',
					},
					{
						id: 327,
						title: 'Okamura',
						url: 'about-us/craftspeople/okamura',
					},
					{
						id: 328,
						title: 'Pablo',
						url: 'about-us/craftspeople/pablo',
					},
					{
						id: 329,
						title: 'Precedent',
						url: 'about-us/craftspeople/precedent',
					},
					{
						id: 330,
						title: 'Restwell',
						url: 'about-us/craftspeople/restwell',
					},
					{
						id: 336,
						title: 'Revolution Furnishings',
						url: 'about-us/craftspeople/revolution-furnishings',
					},
					{
						id: 331,
						title: 'Shell Lake Woodcrafters',
						url: 'about-us/craftspeople/shell-lake-woodcrafters',
					},
					{
						id: 332,
						title: 'Shenandoah Furniture, Inc.',
						url: 'about-us/craftspeople/shenandoah-furniture',
					},
					{
						id: 333,
						title: 'Sielaff',
						url: 'about-us/craftspeople/sielaff',
					},
					{
						id: 334,
						title: 'Spectra',
						url: 'about-us/craftspeople/spectra',
					},
					{
						id: 345,
						title: 'The New Traditionalists',
						url: 'about-us/craftspeople/the-new-traditionalists',
					},
					{
						id: 346,
						title: 'True Residential',
						url: 'about-us/craftspeople/true-residential',
					},
					{
						id: 335,
						title: 'Urban Wood Project',
						url: 'about-us/craftspeople/urban-wood-project',
					},
					{
						id: 344,
						title: 'Wood Castle',
						url: 'about-us/craftspeople/wood-castle',
					},
					{
						id: 337,
						title: 'Younger Furniture',
						url: 'about-us/craftspeople/younger-furniture',
					},
				],
			},
			{
				id: 40,
				title: 'Defining Modern Style',
				url: 'defining-modern-style',
			},
			{
				id: 50,
				title: 'Product Recalls',
				url: 'product-recalls',
			},
			{
				id: 60,
				title: 'Sustainable Wood Sourcing Policy',
				url: 'responsible-wood-sourcing-policy',
			},
			{
				id: 70,
				title: 'California Transparency in Supply Chains Act Disclosure',
				url: 'ca-transparency-act',
			},
		],
	},
];
